import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '60 minutes',
  session: '3 session',
  results: 'Results 6-8 weeks and they permanent',
}

const questions: Array<Question> = [
  {
    question: 'Does the fat come back?',
    answer:
      'Good news! The fat cells are permanently destroyed, so expect long-lasting' +
      ' results if you keep up the diet and exercise. Aqualyx works with your body: ' +
      'after the excess fat cells are dissolved, they are expelled naturally via the lymphatic system.',
  },
  {
    question: 'Liposuction vs fat freezing vs fat dissolving injections',
    answer:
      'Aqualyx isn’t designed for larger areas like love handles because ' +
      'multiple injections can lead to weeks of swelling. ' +
      'Think of Aqualyx as fat removal and body shaping rather than weight loss. ' +
      'For larger areas, consider fat freezing or liposuction.',
  },
  {
    question: 'How does Aqualyx work?',
    answer:
      'Aqualyx injections only take about 15 minutes but allow extra time for your ' +
      'Ada Aesthetics consultation. A water-based solution is injected into ' +
      'small areas of unwanted fat, which permanently destroys your fat cells. ' +
      'Most clients notice a difference within six weeks, but you may need a ' +
      'course, four weeks apart, for full results.',
  },
  {
    question: 'Does Aqualyx hurt?',
    answer:
      'The injections contain a local anaesthetic, and many people find the ' +
      'procedure more comfortable than expected. If you are needle-phobic,' +
      ' your practitioner can also apply numbing cream beforehand.' +
      ' Expect some discomfort afterwards, especially redness and swelling;' +
      ' swelling can last four weeks. ',
  },
]

const FatDissolving: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Fat-dissolving – Aqualyx - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax titleName="Fat-dissolving – Aqualyx" customClass="page_title" />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="excerpt">
                Have you got stubborn areas of fat that just won’t shift? Does
                your muffin top or cellulite ruin your shape in clothes? Perhaps
                your double chin ruins your profile and makes your face look
                heavier and older than you feel.
              </p>
              <p>
                If you are losing body confidence, fat-dissolving injections can
                smooth away bulges without liposuction.
              </p>
              <p>
                At Ada Aesthetics, we are proud to offer our clients permanent
                fat loss without surgery: Aqualyx fat dissolving injections.
                This safe body shaping treatment sculpts and tightens the face
                and body and body by permanently dissolving stubborn areas of
                fat.
              </p>
              <p>
                Aqualyx can treat the following areas: <br />
                • Bingo wings <br />
                • Armpits <br />
                • Cellulite <br />
                • Saddlebags <br />
                • Knees <br />
                • Ankles <br />
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default FatDissolving

export const pageQuery = graphql`
  query FatDissolving {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
